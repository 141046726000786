.mainContainer {
    width: 100vw;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* @supports (height: 100dvh) {
    .mainContainer {
        height: 100dvh;
    }
}

@supports not (height: 100dvh) {
    .mainContainer {
        height: 100vh;
    }
} */

.formContainer {
    width: min(80vw, 800px);
}

.formContainerLarge {
    width: min(80vw, 1200px);
}
